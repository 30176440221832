@import "../../styles/Responsive.scss";

.Footer {
  text-transform: uppercase;
  margin: 3rem 0 3rem 0.75rem;
  padding-top: 1rem;

  .Logos {
    margin-left: -2rem;
    
    img {
      max-width: 250px;
      max-height: 40px;
      margin-right: 1rem;
    }

    .rokion {
      max-height: 30px;
    }
  }

  .Copyright {
    font-size: 0.8rem;
    margin-top: 2rem;
  }

  @media screen and (max-width: $break1) {
    margin: 1rem 2rem;
    .Logos {
      overflow: hidden;
      margin-left: 0;

      img {
        max-width: 200px;
        float: left;
        clear: left;
        margin-bottom: 2rem;
      }
    }

    .Copyright {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: $break2) {
    margin: 0.5rem;

    .Logos {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0;

      > a {

        > img {
          height: 25px;
          margin: 0;
          padding: 0;
        }
      }
    }
    .Copyright {
      margin: 2rem 0 1rem;
      text-align: center;
    }
  }
}
