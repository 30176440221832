.EnterpriseSearchBar {
  margin-right: 1rem;
  input {
    padding-left: 2rem;
    background-image: url("../../../public/img/icn-search.png");
    background-size: 1.3rem;
    background-repeat: no-repeat;
    background-position: 0.5rem;
    height: 42px;
    width: 315px;
  }
}