$gray: #545859;
$lightGray: #eee;
$black: #000;
$white: #fff;
$blue: #0000af;
$green: #02cd9f;
$red: #ff3366;
$orange: #fe5000;
$textGray: #707070;
$statusGray: #b3b3b3;
$selectedRow: $lightGray;
$tooltipBackground: rgba(0,0,0,0.8);
