@import "../../styles/Colors.scss";
@import "../../styles//Responsive.scss";

.HeaderLinks {
  display: flex;
  margin-bottom: 0.5rem;
  max-width: 60%;

  > a {
    margin: 0 1rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: $gray;
    text-transform: uppercase;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    &.activeLink img {
      // Change svg icon to black (#000)
      filter: invert(0%) sepia(100%) saturate(7500%) hue-rotate(291deg) brightness(85%) contrast(90%);
    }
    img {
      height: 2.3rem;
      // Change Black svg icon to gray (#818181)
      filter: invert(51%) sepia(0%) saturate(1874%) hue-rotate(203deg) brightness(99%) contrast(80%);
      &:hover {
        // Change svg icon to black (#000)
        filter: invert(0%) sepia(100%) saturate(7500%) hue-rotate(291deg) brightness(85%) contrast(90%);
      }
    }

    position: relative;
    .truncatedtext {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    /* Show the tooltip text when you mouse over the tooltip container */
    &:hover > .tooltiptext {
      visibility: visible;
      transition-delay: 1.5s;
    }
    
    span {
      visibility: hidden;
    }

    /* Tooltip text */
    .tooltiptext {
      visibility: hidden;
      width: 180px;
      height: auto;
      background-color: $tooltipBackground;
      color: #fff;
      text-align: center;
      padding: 5px 8px;
      border-radius: 6px;
      
      font-family: 'Camber W01 Regular', sans-serif;
      text-overflow: unset;
      overflow: hidden;
      white-space: normal;
      /* Position the tooltip text */
      position: absolute;
      z-index: 9999;
      pointer-events: none;
      top: 2.8rem;
      left: -75px;

      &::after {
        content: " ";
        position: absolute;
        bottom: 100%; /* At the top of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent $tooltipBackground transparent;
      }
    }
  }
}
