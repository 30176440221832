@import "../../styles/Colors.scss";
@import "../../styles/Responsive.scss";

.PageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.PageHeaderHidden {
    margin-bottom: 1rem;
  }

  > .HeaderLinks {
    margin-bottom: 0;
  }

  @media screen and (max-width: $break1) {
    flex-direction: column;
    align-items: center;
    margin: 0.25rem 0;

    > h1 {
      text-align: center;
      margin-left: 0;
    }
    
    > .HeaderLinks {
      max-width: 100%;
      margin: 1rem 0;

      > a:first-child {
        margin-left: 0;
      }

      > a > .tooltiptext {
        display: none;
      }
    }
  }
}
