@import "./styles/Colors.scss";
@import "./styles/Responsive.scss";
@import url(./fonts/fonts.scss);

body,
html {
  font-family: "Camber W01 Regular", sans-serif;
  background-color: $lightGray;
  color: $gray;
}

.mr-auto, .mx-auto {
  margin-right: auto!important;
}

.navbar .container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.container {
  z-index: 100;
  position: relative;
  max-width: 1130px;
}

.AppBoundary {
  .Boundary {
    padding: 0 1rem 2.5rem;
    max-width: 1130px;
  }
}
#root {
  overflow: auto;
}
#root > div > .container > div:first-child {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}

.withSubtitle {
  h2 {
    margin: 0.5rem 0 2rem 0.75rem;
    text-transform: uppercase;

    a {
      color: $orange;      
    }
  }
}

.Collapsible:focus {
  outline: none;
}

.PageContainer {
  padding: 1rem;
  background-color: $white;
}

h1,
.h1 {
  text-transform: uppercase;
  color: $blue;
  font-family:"Camber W01 Regular";
  font-size: 2.3rem;
  margin-left: 0.75rem;
}
.PageHeader {
  margin-bottom: 2rem;
  h1 {
    margin-bottom: 0;
  }
}
h2,
.h2 {
  margin-left: 0.75rem;
}

h3,
.h3 {
  margin-left: 0.75rem;
}

a {
  color: $blue;
  text-decoration: none;

  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}

table,
.table {
  background-color: $white;
  color: $gray;
  margin-bottom: 3rem;

  th {
    font-family:"Camber W01 Medium";
  }

  tbody {
    td {
      font-family:"Camber W01 Medium";
    }
  }
  input[type=checkbox] {
    margin-left: 0;
  }
}

.btn {
  border-radius: 0;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;

  &.btn-primary {
    background-color: $black;
    font-family:"Camber W01 Medium";
    border-color: $black;

    &:hover {
      background-color: $gray;
      border-color: $gray;
    }
  }
}
.btn-secondary {
  border: 1px solid $gray;
  background-color: $white;
  color: $gray;

  &:hover {
    color: $white;
    background-color: $gray;
    
  }
}

input[type=text],
select {
  font-family:"Camber W01 Medium";
}

label {
  margin-left: 0.75rem;
}

.bg-success,
.bg-danger,
.bg-secondary,
.bg-retired {
  color: $white;

  &:focus {
    color: $white;
  }
}

.bg-success,
.alert-success {
  background-color: $green !important;
  color: $white;
}

.bg-danger,
.alert-danger {
  background-color: $red !important;
  color: $white;
}

.bg-secondary {
  background-color: $statusGray !important;
}

.bg-retired {
  background-color: $black !important;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.form-control,
.uneditable-input {   
  border-radius: 0 !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-control:focus,
.uneditable-input:focus {   
  border-color: $orange !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(254, 80, 0, 0.6) !important;
  outline: 0 none !important;
}

button:focus {
  outline: none;
}

.btn:focus {
  border-color: $orange !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(254, 80, 0, 0.6) !important;
  outline: 0 none !important;
}

.btn:active {
  background-color: $gray !important;
}

strong {
  font-family:"Camber W01 Bold";
}

button {
  background-color: transparent;
  border: 0;

  .ml-2 {
    position: relative;
    top: -2px;
  }
}

.DateRangePicker strong {
  font-family:"Camber W01 Medium";
}

.ServicePagePulseLoader {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.PagePulseLoader {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.PulseLoader {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

p.FourOhThree {
  margin: 10;
}

@media screen and (max-width: $break1) {
  .hide-on-mobile {
    display: none !important;
  }
  .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .navbar.navbar-light .navbar-toggler-open .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 0, 175, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
  }
  .navbar > .container {
    padding: 0;
  }
  .container {
    padding: 0 10px;
  }
  .row {
    flex-direction: column;

    .col {
      max-width: 100%;
    }
  }
  .form-group {
    input[type=checkbox].form-check-input {
      position: relative;
      margin-left: 0;
    }
    > button[type=submit]:first-child {
      margin-left: 0.5rem;
    }
  }

  .withSubtitle {
    h2 {
      margin-top: 1rem;
      margin-left: 0;
      text-align: center;
  
      a {
        margin-left: 0;
      }
    }
  }
}

.SingleDatePicker_picker {
  z-index: 1000;
}
