.Boundary {
  margin-top: 2.5rem;

  .btn {
    margin-left: 1rem;
  }

  p {
    margin: 2rem 1rem;
    max-width: 720px;
  }
}