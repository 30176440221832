@import "../../styles/Colors.scss";
@import "../../styles/Responsive.scss";

.navbar {
  background-color: $white !important; 
  border-bottom: 1px solid $lightGray;
  margin-bottom: 1rem;

  // &:after {
  //   content:"";
  //   background-color: $white;
  //   height: 119px;
  //   width: 100%;
  //   position: absolute;
  //   bottom: -121px;
  //   left: 0;
  // }

  @media screen and (max-width: $break1) {
    &:after {
      background-color: $lightGray;
    }
  }
}

.navbar-brand {
  text-transform: uppercase;
  font-family:"Camber W01 Regular";
  font-size: 2rem;
  margin-left: 0.75rem;

  img {
    max-width: 300px;
  }

  @media screen and (max-width: $break2) {
    img {
      max-width: 200px;
    }
  }
}

.navbar-toggler {
  border: 0;
  margin-right: -0.75rem;
}

.navbar-light .navbar-text {
  color: $blue;
  text-transform: capitalize;
  font-family: "Camber W01 Medium";
}

.navbar-toggler-mobile {
  display: none;
}
@media screen and (max-width: $break1) {
  .navbar .navbar-brand {
    margin-left: 0;
     > a {
      display: flex;
      flex-direction: column;
    }
  }
  .navbar-toggler-mobile {
    display: block;
    &.EnterpriseSearchBar {
      margin-right: 0;
      > input[type=search] {
        width: 100%;
      }
    }
  }
  .navbar-toggler-web {
    display: none;
  }
}
.navbar-nav {
  align-items: flex-end;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav button {
  color: $gray;

  &:hover {
    color: adjust-color($gray, $red: 34, $green: 34, $blue: 34);
    text-decoration: underline;
  }
}

.navbar-toggler-icon {
  stroke {
    fill: $gray;
  }
}

.Sentry {
  border: 0 !important;;
  color: $blue !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  margin-right: 30px;
  font-family:"Camber W01 Medium";

  &:hover {
    background-color: transparent !important;
    text-decoration: underline;
  }

  @media screen and (max-width: $break2) {
    display: none;
  }
}