@import "../../styles/Responsive.scss";

.LoginScreen.container {
  height: 100%;
  margin-top: 150px;
  max-width: 600px;

  h1 {
    text-align: center;
    
    img {
      max-width: 450px;
      width: 80%;
    }
  }
}

.ResetLink {
  display: inline-block;
  margin: 2rem 0;
}

.ResetLinkContainer {
  text-align: center;
}

@media screen and (max-height: $break1) {
  .LoginScreen.container {
    position: absolute;
    bottom: 0;
    top:0;
    left:0;
    right:0;
    margin: 0;
    max-width: unset;
    padding: 0;

    > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      max-width: 600px;
      margin: auto;
      padding: 0 40px;
    }

    > div h1 {
      margin: 0;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-height: 400px) {
  .LoginScreen.container {
    position: relative;
    height: fit-content;
  }
}